import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/sigma',
      name: 'sigma',
      component: () => import(/* webpackChunkName: "sigma" */ '@/views/sigmaCompareMWB.vue'),
      props: (route) => ({
        LRSMode: Boolean(route.query.lrs),
        debugMode: Boolean(route.query.debug),
        expertGraphPath: String(route.query.expertGraphPath),
        expertGraphsOnly: Boolean(route.query.expertGraphsOnly)
      })
    }
  ]
})
