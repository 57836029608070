<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="dark" fixed="top">

      <b-navbar-toggle target="nav_collapse"/>

      <b-navbar-brand  to="/" v-if="!$store.state.hidePageElements">
      <!-- <img src="@/assets/logo_small.png" width="100rem"/> -->
        MWB-Graphen
      </b-navbar-brand>
      <div class="customVerticalLine d-none d-sm-block d-sm-none" v-if="!$store.state.hidePageElements" />

      <b-navbar-nav class="d-none d-sm-block d-sm-none" v-if="!$store.state.hidePageElements">
        <b-breadcrumb v-if="!isEmpty(breadcrumbItems)" :items="breadcrumbItems"></b-breadcrumb>
      </b-navbar-nav>
      <b-navbar-nav class="d-block d-sm-none d-md-none">
      </b-navbar-nav>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <!-- <b-button v-if="$route.name === 'sigma'" class="mr-2 text-white" variant="flat" v-b-tooltip.hover title="Hier geht es zu einem kurzen Erklär-Video des Angebots" href="https://moodle2.uni-leipzig.de/pluginfile.php/2485049/mod_resource/content/3/Screencast%20Wissennetze02%20-%20Repariert.mp4" target="_blank">
            <font-awesome-icon icon="play-circle" />
          </b-button> -->
          <b-button v-if="$route.name === 'sigma'" class="mr-2 text-white" variant="flat" v-b-tooltip.hover title="Hier findest du ein PDF- Dokument, in dem die Funktionen des Interaktiven Wissensnetzes erläutert werden" href="https://cloud.tech4comp.erzw.uni-leipzig.de/s/fLZC3pFRaALpX5L" target="_blank">
            <font-awesome-icon icon="question-circle" />
          </b-button>
          <b-button v-if="$route.name !== 'about' && $route.name !== 'sigma'" class="mr-2 text-white" variant="flat" v-b-modal.learningModal>
            <font-awesome-icon icon="question-circle" />
          </b-button>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <b-modal id="learningModal" hide-footer>
      <template #modal-title>
        Informationen zu dieser Ansicht
      </template>
      <tutorialContent/>
    </b-modal>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import tutorialContent from '@/components/tutorialContent'

export default {
  components: { tutorialContent },
  name: 'navbar',
  computed: {
    breadcrumbItems () { // TODO replicates somehow routes.js.... think about better way to implement it
      const name = this.$route.name
      const toReturn = [{ text: 'Startseite', to: '/' }]
      if (name === 'sigma')
        toReturn.push({ text: 'Sigma-Darstellung' })
      return toReturn
    }
  },
  methods: {
    isEmpty
  }
}
</script>

<style scoped>
  .breadcrumb {
    background-color: rgba(72, 78, 84, 0);
    margin-top: 0;
    margin-bottom: 0;
    padding: .5rem .5rem .5rem 0.2rem;
  }
  .breadcrumb-item.active > span {
    color: rgb(150, 150, 150);
  }
  .breadcrumb-item > a , .breadcrumb-item::before {
    color: rgb(255, 255, 255) !important;
  }
  #basketButton.margin {
    margin-right: 1rem;
  }
  .navbar-toggler {
    margin-right: 0.5rem;
  }
  .navbar-brand {
    margin-right: 0.5rem;
  }
  .navbar-collapse.show {
    padding-top: 1rem;
  }
  .customVerticalLine {
    margin-left: 0.5rem;
    margin-right: 0.7rem;
    border-left: 1px solid grey;
    height: 2rem;
  }
</style>
