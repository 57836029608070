<template>
  <b-container fluid id="app">
    <b-row id="header" v-if="!$store.state.hidePageElements">
      <b-col>
        <navbar/>
      </b-col>
    </b-row>
    <b-row id="main">
      <b-col cols="12">
        <transition name="fade" mode="out-in" appear>
          <router-view/>
        </transition>
      </b-col>
    </b-row>
    <b-row id="footer" v-if="!$store.state.hidePageElements">
      <b-col>
        <b-navbar variant="light">
          <b-navbar-nav class="ml-auto">
            <b-nav-item href="https://tech4comp.de/impressum-2/">Impres&shy;sum</b-nav-item>
            <b-nav-item href="https://tech4comp.de/datenschutzerklaerung/">Datenschutz&shy;erklärung</b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import navbar from '@/components/navbar.vue'

export default {
  name: 'app',
  components: {
    navbar
  },
  data () {
    return {}
  }
}
</script>

<style>
  html, body{
    width: 100%;
    height: 100%;
  }
  #app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  #main {
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 1rem;
    padding-bottom: 0;
  }
  #header, #main, #footer {
    flex-shrink: 0;
  }
  #footer > .col {
    padding: 0 0 0 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .badge > .svg-inline--fa {
    margin-top: 0.1rem;
  }
  .card-text {
    hyphens: auto;
    text-align: justify;
  }
  .verticalLine {
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #666;
    z-index: 1;
    /* height: 2rem; */
    /* border-left: 1px solid grey; */
  }
</style>

<style scoped>
  .badge {
    min-width: 1.4rem;
  }
  h4 {
    margin-bottom: 0;
  }
  h4:hover {
    filter: brightness(80%) !important;
  }
</style>
