<template>
  <div>
    <b-card-group>
      <b-card class="selectable" @click="$router.push({ name: 'sigma' })">
        <div slot="header">
          <h4 class="float-left">Sigma MWB</h4>
          <h4 class="float-right"><font-awesome-icon icon="shapes"/></h4>
        </div>
        <b-card-text>
          <p>Öffnen Sie Individual-Modelle & Cluster-Karten (als lokale Datei von Ihrem PC) in der Sigma-Darstellung, um diese zu erkunden, zu analysieren und ggf. zu einer zweiten Karte zu vergleichen</p>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>

export default {
  name: 'home',
  data () {
    return {}
  }
}
</script>

<style scoped>
  .card-header > div > h4 {
    margin-top: 0.4rem  ;
    margin-bottom: 0;
  }
  .card {
    margin-bottom: 1rem !important;
    min-width: 22rem;
  }
  .card.selectable:hover {
    filter: brightness(98%);
    cursor: pointer !important;
  }
  .card-text {
    text-align: left;
  }
  .badge {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
  .customDisabled > .card-header, .customDisabled > .card-body {
    opacity: 0.5;
  }
</style>
